















































































import {Component, Prop, Watch} from 'vue-property-decorator';
import {mixins} from 'vue-class-component';
import DataFormat from '@/shared/utils/dataFormat';
import {reservationModule} from '@/store';
import {Notify} from 'quasar';
import {EventBus} from '@/config/config';
import moment from 'moment';

@Component({
    components: {
        'alert-modal': () => import('@/components/popup/AlertModal.vue'),
        'tag-list': () => import('@/components/common/TagList.vue'),
        'reserve-before-check': () => import('@/components/popup/ReserveBeforeCheck.vue')
    }
})
export default class ReserveAddInfoModal extends mixins(DataFormat) {
    @Prop()
    public place!: any;
    @Prop()
    public reserveParams!: {
        date: string;
        peopleCount: number;
        peopleDetailCount: object;
        time: string;
        tableType: string;
        requestText: string;
        listBuyOption: any;
        calendarTableType: string;
        listTime: any;
        name: string;
        phone: string;
        email: string;
        countryCode: string;
        totalPrice: number;
    };
    @Prop()
    public selectLang!: any;

    public $refs!: {
        scrollWarp: HTMLElement;
    };

    public reserveAddOnData: any = {};
    public isCheckUse: any = {};
    public nextDisable: boolean = true;
    public topElHeight: number = 0;
    public isModalClose: boolean = false;
    public errorMessage: string = '';
    public textAreaHeight: number = 120;
    public loading: boolean = false;
    public ReserveBeforeCheckStatus: boolean = false;

    public modalOpened() {
        const el: any = document.querySelector('.certification-area');
        this.topElHeight = el.scrollHeight;
        this.nextDisableFn();
    }

    public back() {
        this.$modal.hide('reserveAddInfo');
    }

    public updateAddOnData(data: any, key: any) {
        this.reserveAddOnData[key] = data;
        this.$nextTick(() => {
            this.nextDisableFn();
        });
    }

    //알러지 Textarea height 체크
    public inputTextAreaHeight() {
        const textarea = document.querySelector('.text-area.allergy' as any);
        if (textarea) {
            textarea.addEventListener('input', () => {
                this.textAreaHeight = textarea.scrollHeight;
            });
        }
    }

    public nextDisableFn() {
        let isDisable = false;

        for (let i = 0; i < this.reservationAddInfo.length; i++) {
            const infoItem = this.reservationAddInfo[i];

            if (!infoItem.required) continue;

            if (infoItem.show_yn && this.isCheckUse[infoItem.key] === '0') {
                this.reserveAddOnData[infoItem.key] = [];
                continue;
            }

            // 필수 항목 및 자식 요소 검사 (통합)
            if (!this.reserveAddOnData[infoItem.key] ||
                ((['multi_select', 'single_select'].includes(infoItem.input_type)) &&
                    this.reserveAddOnData[infoItem.key].length === 0 ||
                    infoItem['child_options'].some(subItem => subItem.active && subItem.show_textarea && subItem.detail === '')
                ) || (infoItem.input_type === 'textarea' && this.reserveAddOnData[infoItem.key] === '')) {
                isDisable = true;
                break;
            }
        }

        this.nextDisable = isDisable;
        return;
    }

    public ReserveBeforeCheckUpdate() {
        this.ReserveBeforeCheckStatus = false;
        this.$nextTick(() => {
            this.cartPayment('normal');
        });
    }
    //카트 생성
    public cartPayment(type?: any) {
        if (this.loading) {
            return;
        }
        this.loading = true;
        const {time, listBuyOption, tableType, peopleCount, name, phone, email, countryCode, requestText} = this.reserveParams;
        const date = moment(this.reserveParams.date).format('YYYY-MM-DD');

        let cartArgs: any = {
            date: moment(`${date} ${time}`).format('YYYY-MM-DD HH:mm:ss'),
            buy_option: JSON.stringify(listBuyOption),
            person: peopleCount,
            name,
            phone,
            table_type: tableType,
            is_foreigner: 0,
            message: requestText
        };

        // 외국인 정보 추가
        if (email) {
            cartArgs.is_foreigner = 1;
            cartArgs.email = email;
            cartArgs.country_code = countryCode;
        }

        //인원 구분 데이터 병합
        if (this.reserveParams.peopleDetailCount) {
            Object.entries(this.reserveParams.peopleDetailCount).forEach(([key, value]) => {
                cartArgs[`person_${key}`] = value;
            });
        }

        // 추가 정보 데이터 병합
        if (this.reserveAddOnData) {
            Object.entries(this.reserveAddOnData).forEach(([key, value]) => {
                cartArgs[key] = value;
            });
        }

        // 일반/대기 예약 개발
        if (
            this.place.reservation_method === 'normal' ||
            this.place.reservation_method === 'os_normal'
        ) {
            if (this.place.reserve_checklist.length > 0 && type !== 'normal') {
                this.ReserveBeforeCheckStatus = true;
                this.loading = false;
                return;
            }
            if (
                this.place.reserve_checklist.length === 0 ||
                (this.place.reserve_checklist.length > 0 && type === 'normal')
            ) {
                delete cartArgs.buy_option;
                cartArgs.place_id = this.place.id;

                reservationModule
                    .fetchReservationRegister(cartArgs)
                    .then((res) => {
                        if (!res.status) {
                            this.errorMessage = res.error.message;
                            EventBus.$emit('errorMessage', this.errorMessage);
                            this.$modal.show('alert');
                        } else {
                            this.$router.push({ path: `/payment/result/success?reservation_id=${res.data.id}` });
                            document.querySelector('html' as any).classList.remove('open-modal');
                        }
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            }
            return;
        }

        reservationModule
            .fetchCart(cartArgs)
            .then((res) => {
                if (!res.status) {
                    this.errorMessage = res.error.message;
                    EventBus.$emit('errorMessage', this.errorMessage);
                    this.$modal.show('alert');
                } else {
                    this.$router.push({name: 'payment', params: {cartId: res.data.id}});
                    document.querySelector('html' as any).classList.remove('open-modal');
                }
            })
            .finally(() => {
                this.loading = false;
            });
    }

    //추가 정보 변경 시
    @Watch('reserveAddOnData')
    public watchAddOnData() {
        this.nextDisableFn();
    }

    get reservationAddInfo() {
        return reservationModule.reservationAddInfo;
    }
    get reserveChecklist() {
        if (this.place && this.place.reserve_checklist && this.place.reserve_checklist.length) {
            const re: any = [];
            this.place.reserve_checklist.filter((row: any, index: any) => {
            re[index] = {};
            re[index].value = row;
            re[index].active = false;
            });
            return re;
        }
        return null;
    }
}
